import React from 'react';

const Modal = (props: {
  children: React.ReactNode;
  className?: string;
  borderStyle?: 'white' | 'gray' | 'none';
  bgStyle?: 'black-blur' | 'transparent' | 'black-transparent-blur';
  modalBgStyle?: 'black' | 'transparent';
  stopPropagateMouseDown?: boolean;
}): JSX.Element => {
  const {
    children,
    borderStyle,
    bgStyle,
    stopPropagateMouseDown,
    modalBgStyle,
  } = props;

  let borderClassNames =
    'border-2 border-transparent border-solid box-border bg-clip-padding before:gradientBorder';
  switch (borderStyle) {
    case 'white':
      borderClassNames = 'border border-white';
      break;
    case 'gray':
      borderClassNames = 'border border-[#303436]';
      break;
    case 'none':
      borderClassNames = '';
      break;
  }

  let bgClassNames = 'bg-black';
  switch (bgStyle) {
    case 'black-blur':
      bgClassNames = 'bg-lp-black-001';
      break;
    case 'transparent':
      bgClassNames = 'bg-transparent';
      break;
    case 'black-transparent-blur':
      bgClassNames = 'bg-lp-black-002';
      break;
  }

  let modalBgClassNames = 'bg-black';
  switch (modalBgStyle) {
    case 'black':
      break;
    case 'transparent':
      modalBgClassNames = 'bg-transparent';
      break;
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    if (stopPropagateMouseDown) {
      event.stopPropagation();
    }
  };

  return (
    <div onMouseDown={handleMouseDown}>
      <div className={`opacity-80 fixed inset-0 z-50 ${modalBgClassNames}`} />
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto scrollbar fixed inset-0 z-50 outline-none focus:outline-none'>
        <div
          className={`relative flex items-center m-auto rounded-xl ${borderClassNames} ${bgClassNames} ${props.className}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export { Modal };
